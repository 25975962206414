import { Container, Nav, Tab } from "react-bootstrap";
import React, { useState, useEffect, useReducer } from "react";
import { useTranslation } from 'react-i18next';
import PageTitle from '../pageTitle/template_01';
import VIPContainer from '../../dynamic_section/dynamic_2/template_01';
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { useStoreDispatch, useStoreState, APP_STORE_ACTION } from '../../common/storeContext'

import "./template_01.css";
import { TENANT_NAME } from "../../common/constants";


const VIP_ACTION = {
  SET_DEFAULT_VIP_TABS: 'SET_DEFAULT_VIP_TABS',
  SET_VIP_TABS: 'SET_VIP_TABS',
  SET_SELECTED_VIP_TAB: 'SET_SELECTED_VIP_TAB',
}

const initialVipData = {
  vipTabs: [
    { code: "ABOUTUS", name: "vip_about_us", toggle: "aboutUsToggle", imageName: 'vip_aboutUs' },
    { code: "FAQ", name: "vip_faq", toggle: "faqToggle", imageName: 'vip_faq' },
    { code: "TNC", name: "vip_tnc", toggle: "tncToggle", imageName: 'vip_tnc' },
    { code: "BENEFIT", name: "vip_benefit", toggle: "benefitToggle", imageName: 'vip_benefits' },
    { code: "REVIEW", name: "vip_review", toggle: "reviewToggle", imageName: 'vip_reviews' }
  ],

  selectedVipTab: { code: "ABOUTUS", name: "vip_about_us", toggle: "aboutUsToggle", imageName: 'vip_aboutUs' },
}

const vipReducer = (state, action) => {
  switch (action.type) {

    case VIP_ACTION.SET_VIP_TABS: {
      const vipTabs = action.payload
      return { ...state, vipTabs, selectedVipTab: vipTabs?.length > 0 ? { ...vipTabs[0] } : { code: "", name: "", toggle: "", imageName: '' } };
    }

    case VIP_ACTION.SET_SELECTED_VIP_TAB: {
      const selectedVipTab = state.vipTabs.find((vipTabs) => vipTabs.code === action.payload)
      return { ...state, selectedVipTab };
    }


    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const Vip = (props) => {
  const appState = useStoreState()
  const { t } = useTranslation();
  const { desktopToggle, mobileToggle, bgSection, mobileTitleToggle, desktopBannerToggle, mobileBannerToggle, mode,vipContentToggle, joinNowContentToggle, ...tabProps } = props;
  const [vipState, vipDispatch] = useReducer(vipReducer, initialVipData)

  const onChangeVipTab = (newValue) => {
    vipDispatch({ type: VIP_ACTION.SET_SELECTED_VIP_TAB, payload: newValue })
  }

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })

  // useEffect(() => {
  //   const getCurrentTab = vipState.defaultVipTabs.find(tab => tabProps[tab.toggle]);
  //   if (getCurrentTab) {
  //     vipDispatch({ type: VIP_ACTION.SET_VIP_TABS, payload: getCurrentTab });
  //   }
  // }, [vipState.defaultVipTabs, tabProps]);

  // function getCurrentTab(tabProps) {
  //   for (let i = 0; i < vipState.defaultVipTabs.length; i++) {
  //     if (tabProps[vipState.defaultVipTabs[i].toggle]) {
  //       return vipState.defaultVipTabs[i].code;
  //     }
  //   }
  //   return null;  
  // }
  // vipDispatch({ type: VIP_ACTION.SET_VIP_TABS, payload: getCurrentTab(tabProps) })


  if ((desktopToggle || mobileToggle) === true) {
    return (
      <section id="vip_01" className={`${desktopToggle ? "" : "d-lg-none"} ${mobileToggle ? "" : "d-none d-lg-block"} ${bgSection ? 'bgSectionVip' : ''}`}>
        <div className="vip_body">
          {mobileTitleToggle && (<PageTitle desktopToggle={false} mobileToggle={true} title={`vip`} id={'vip_title'} />)}
          <VIPContainer desktopToggle={desktopBannerToggle} mobileToggle={false} label={'vip_banner'} id={'vip_banner'} supportLanguage={true} />
          <VIPContainer desktopToggle={false} mobileToggle={mobileBannerToggle} label={'vip_banner'} id={'vip_banner_m'} supportLanguage={true} />

          <div className={`vip_container ${!isDesktopOrLaptop ? 'vip_container_m' : ''}`}>
            <div className="vipTabWrap">
              <div className="vipTab container">
                {vipState.vipTabs?.map(function (item, index) {
                  if (tabProps[item.toggle]) {
                    if (mode === 'scroll') {
                      return (<a href={`#${item.code}`} key={item.code}>
                        <div className={`vipTab_selection ${item.code === vipState.selectedVipTab.code ? 'color_active active' : ''}`} key={`${item.code}`} onClick={() => onChangeVipTab(item.code)}>
                          {t(item.name)}
                        </div>
                      </a>)
                    } else {
                      return (<NavLink key={item.code} to={`#${item.code}`}>
                        <div className={`vipTab_selection ${item.code === vipState.selectedVipTab.code ? 'color_active active' : ''}`} key={`${item.code}`} onClick={() => onChangeVipTab(item.code)}>
                          {t(item.name)}
                        </div>
                      </NavLink>)
                    }

                  }
                  return null;

                })}
              </div>
              {/* {console.log('vipState.vipTabs',vipState.vipTabs)} */}
            </div>
            <div className="vip_tabContentContainer">
              {vipState.vipTabs?.map(function (item, index) {
                if (tabProps[item.toggle]) {
                  if (mode === 'scroll') {
                    return (<section key={item.name} id={item.code} className={`vip_scrollContent`}>
                      <VIPContainer desktopToggle={true} mobileToggle={false} label={`${item.name}`} id={`${item.imageName}`} supportLanguage={true} addContainer={true} />
                      <VIPContainer desktopToggle={false} mobileToggle={true} label={`${item.name}`} id={`${item.imageName}_m`} supportLanguage={true} addContainer={true} />
                    </section>)
                  } else {
                    return (<div key={item.name} className={`vip_tabContent  ${item.code === vipState.selectedVipTab.code ? 'd-block' : 'd-none'}`}>
                      <VIPContainer desktopToggle={true} mobileToggle={false} label={`${item.name}`} id={`${item.imageName}`} supportLanguage={true} addContainer={true} />
                      <VIPContainer desktopToggle={false} mobileToggle={true} label={`${item.name}`} id={`${item.imageName}_m`} supportLanguage={true} addContainer={true} />
                    </div>)
                  }

                }
                return null;

              })}
              {(vipContentToggle&&isDesktopOrLaptop) && (< VIPContainer desktopToggle={true}
                mobileToggle={false} bgSection={true} label={`vip content`} id={`vip_content`} supportLanguage={
                  true} titleToggle={false} titleIconToggle={
                    true} linkTo={``} addContainer={true} />)}







{(vipContentToggle&&!isDesktopOrLaptop) && (< VIPContainer desktopToggle={false}
                mobileToggle={true} bgSection={true} label={`vip content_m`} id={`vip_content_m`} supportLanguage={
                  true} titleToggle={false} titleIconToggle={
                    true} linkTo={``} addContainer={false} />)}
            </div>

            {(joinNowContentToggle && !appState.loggedIn) && (<div className="container vip_joinNowContent">
              <div className="vip_joinNowContent_title">BE OUR {TENANT_NAME.toUpperCase()} PRESTIGIOUS VIP</div>
              <div className="vip_joinNowContent_subtitle">The advantage is in your hands.Join the {TENANT_NAME.toUpperCase()} VIP Club today right now!</div>
              <Link to="/register" className="vip_joinNowContent_button"><button>{t('join_now')}</button></Link>
            </div>)}
          </div>



        </div>
      </section>
    );
  }
  else {

  }
}

export default Vip;
